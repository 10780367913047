<template>
  <div style="display: flex">
    <LeftNavigation />
    <div class="vca-page">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import LeftNavigation from "@/components/layout/LeftNavigation";
export default {
  name: "EmailsView",
  components: {
    LeftNavigation,
  },
  created() {
    this.$store.dispatch("emails/mailboxList");
  },
};
</script>
